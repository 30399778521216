.login {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url(../../images/banner-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .login .wrapper {
    background: rgb(0,171,214);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,115,144,0.5)), to(rgba(84, 84, 84, 0.5)));
    background: linear-gradient(0deg, rgba(0,115,144,0.5) 0%, rgba(84, 84, 84, 0.5) 100%);
    width: 100%;
    height: 100%;
  }

  .login .wrapper .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  
  .login .wrapper .back-button a {
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
  }
  
  .login .wrapper .back-button a i {
    margin-right: 10px;
  }
  
  .login .wrapper .back-button a span {
    font-size: 14px;
  }

  .login .wrapper .center {
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 350px;
    max-width: 90%;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes pulse {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  .login .wrapper .center .logo-banner {
    width: 100%;
    margin-bottom: 60px;
  }
  
  .login .wrapper .center .logo-banner img {
    width: 100%;
    display: block;
    -webkit-animation: pulse 4s infinite;
            animation: pulse 4s infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }

  .login .wrapper .center .login-form {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .login .wrapper .center .login-form .input-group {
    padding: 5px 0;
  }
  
  .login .wrapper .center .login-form .input-group label {
    display: block;
    color: #dddddd;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
  }

  .login .wrapper .center .login-form .input-group input,
  .login .wrapper .center .login-form .input-group input:-internal-autofill-selected,
  .login .wrapper .center .login-form .input-group input:-webkit-autofill {
    display: block;
    padding: 10px;
    border: none;
    outline: none;
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0);
    border-bottom: solid 2px #fff;
    width: 100%;
    border-radius: 0;
    -webkit-transition: 0.2s border;
    transition: 0.2s border;
  }
  
  .login .wrapper .center .login-form .input-group input:-webkit-autofill,
  .login .wrapper .center .login-form .input-group input:-webkit-autofill:hover,
  .login .wrapper .center .login-form .input-group input:-webkit-autofill:focus,
  .login .wrapper .center .login-form .input-group textarea:-webkit-autofill,
  .login .wrapper .center .login-form .input-group textarea:-webkit-autofill:hover,
  .login .wrapper .center .login-form .input-group textarea:-webkit-autofill:focus,
  .login .wrapper .center .login-form .input-group select:-webkit-autofill,
  .login .wrapper .center .login-form .input-group select:-webkit-autofill:hover,
  .login .wrapper .center .login-form .input-group select:-webkit-autofill:focus {
    border: none;
    border-bottom: solid 2px #fff;
    -webkit-text-fill-color: #fff;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
  }
  
  .login .wrapper .center .login-form .btn {
    display: block;
    width: 100%;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.289);
    color: white;
    outline: none;
    border: none;
    font-weight: 600;
    margin-top: 10px;
    -webkit-transition: 0.2s background-color;
    transition: 0.2s background-color;
    right: 15px;
  }
  
  .login .wrapper .center .login-form .btn:hover {
    background-color: rgba(255, 255, 255, 0.46);
  }

  .error {
    background: #F2DEDE;
    color: #A94442;
    padding: 15px;
    width: 95%;
    border-radius: 5px;
    margin: 20px auto;
    text-align: center;
 }